import React from "react"
import { graphql } from "gatsby"
import {
  Section,
  BoxContainer,
  ButtonLink,
  ButtonLevel,
  Picture,
} from "../components/primitives"
import NewsletterSignup from "../components/newsletterSignup"
import Blocks from "../components/blocks"
import {
  FrontPageQuery,
  Wp_Page_Acfhomebanners_BannerEvening,
  Wp_Page_Acfhomebanners_BannerDay,
} from "../../graphql-types"
import Team from "../components/team"
import axios from "axios"
import { PostProps, transformInstagramResult } from "../templates/livewall"
import BackgroundImage, { IFluidObject } from "gatsby-background-image"
import Img from "gatsby-image"
import SEO from "../components/seo"

export const FrontPage: React.FC<{ data: FrontPageQuery }> = ({ data }) => {
  const [posts, setPosts] = React.useState<PostProps[]>([])
  const [isFetching, setIsFetching] = React.useState(true)

  const username = "restaurantwollegras"

  React.useEffect(() => {
    axios
      .get(`https://restaurantwollegras.nl/instagram.json`)
      .then((res) => {
        setPosts(transformInstagramResult(res.data).slice(0, 8))
        setIsFetching(false)
      })
      .catch((err) => console.error(err))
  }, [])

  return (
    <>
      <SEO data={data.wp?.page?.seo || undefined} wp={data.wp || undefined} />
      <Picture
        css={`
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
          min-height: 85vh;

          @media screen and (max-width: 80rem) {
            & > *:first-child > *:last-child {
              padding-top: 6rem;
              padding-bottom: 3rem;
            }

            & > *:last-child > *:last-child {
              padding-top: 3rem;
              padding-bottom: 6rem;
            }
          }

          @media screen and (max-width: 40rem) {
            grid-template-columns: 1fr;
          }
        `}
      >
        <Banner content={data.wp.page?.acfHomeBanners?.bannerDay} title="Dag" />
        <Banner
          content={data.wp.page?.acfHomeBanners?.bannerEvening}
          title="Avond"
        />
      </Picture>
      <Section
        css={`
          background: ${(props) => props.theme.colors.sectionBackground};
        `}
      >
        <BoxContainer
          css={`
            display: grid;
            grid-template-columns: 7fr 5fr;
            align-items: center;
            gap: 10rem;

            @media screen and (max-width: 64rem) {
              grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
              gap: 2rem 4rem;
            }

            @media screen and (max-width: 32rem) {
              grid-template-columns: 1fr;
            }

            h2 {
              font-size: 3.75rem;
              text-transform: uppercase;
              line-height: 1;
              font-family: ${(props) => props.theme.fonts.heading};
              margin-top: 0.175rem;
            }

            & > div:first-child {
              display: flex;

              .gatsby-image-wrapper {
                flex-shrink: 0;
                margin-left: -4rem;
                margin-right: 2rem;
                margin-top: -4rem;

                @media screen and (max-width: 32rem) {
                  display: none !important;
                }
              }
            }

            & > div:last-child {
              overflow: hidden;
              opacity: ${(props) => props.theme.imageOpacity};

              & > div {
                max-width: 60vw;
                min-width: 18rem;
                margin-left: auto;
                margin-right: auto;
                border-radius: 66% 34% 47% 53% / 68% 66% 34% 32%;
              }
            }
          `}
        >
          <div>
            {data.file?.childImageSharp && (
              <Img fixed={data.file?.childImageSharp.fixed} />
            )}
            <Blocks blocks={data.wp.page?.blocks || undefined} />
          </div>
          <div>
            <Img
              fluid={
                data.wp.page?.acfHomeBanners?.imageIntro?.localFile
                  ?.childImageSharp?.fluid
              }
            />
          </div>
        </BoxContainer>
      </Section>
      {data.wp.persons?.edges && (
        <Section
          css={`
          position: relative;

          &:after {
            content: '';
            width: 100%;
            height: 100%;
            background-image: url('${require("../images/dark-border.png")}');
            opacity: ${(props) => (props.theme.theme === "dark" ? 1 : 0)};
            left: 0;
            top: 0;
            background-size: contain;
            background-position: center bottom;
            z-index: 2;
            position: absolute;
            background-repeat: no-repeat;
            pointer-events: none;
          }

          &:before {
            content: '';
            width: 100%;
            height: 100%;
            opacity: ${(props) => (props.theme.theme === "light" ? 1 : 0)};
            background-image: url('${require("../images/light-border.png")}');
            left: 0;
            top: 0;
            background-size: contain;
            background-position: center bottom;
            z-index: 2;
            position: absolute;
            background-repeat: no-repeat;
            pointer-events: none;
          }
      `}
        >
          <BoxContainer
            css={`
              display: flex;
              align-items: center;
              flex-direction: column;

              & > h3 {
                margin-bottom: 1.5rem;
                align-self: flex-start;
              }

              & > a {
                margin-top: 2.5rem;
              }
            `}
          >
            <h3>Ontmoet het team</h3>
            <Team persons={data.wp.persons.edges} />
            <ButtonLink href="/over-ons#team" level={ButtonLevel.secondary}>
              Ontmoet iedereen
            </ButtonLink>
          </BoxContainer>
        </Section>
      )}
      <Section
        css={`
          padding-bottom: 0;
          background: ${(props) => props.theme.colors.sectionBackground};
        `}
      >
        <BoxContainer
          css={`
            display: flex;
            flex-direction: column;
            align-items: center;

            & > * + * {
              margin-top: 1rem;
            }

            p {
              max-width: 24rem;
              text-align: center;
              line-height: 1.75;
            }

            h3 {
              font-size: 3.75rem;
              text-transform: uppercase;
              line-height: 1;
              font-family: ${(props) => props.theme.fonts.heading};
              margin-top: 0.175rem;
              display: flex;
              flex-direction: column;
              align-items: center;

              span {
                font-size: 1.5rem;
                display: block;
              }
            }
          `}
        >
          <h3>
            <span>Wollegras op</span> Instagram
          </h3>

          <ButtonLink level={ButtonLevel.primary} href="/livewall">
            Naar livewall
          </ButtonLink>
        </BoxContainer>
        <div
          css={`
            width: 100%;
            display: flex;
            justify-content: center;
            overflow: hidden;
            padding: 2rem 0;
            margin-top: 4rem;
            margin-bottom: -12rem;

            @media screen and (max-width: 56rem) {
              justify-content: flex-start;
              overflow-x: scroll;
              touch-action: cross-slide-x;
              scroll-snap-type: x mandatory;
            }

            & > * {
              flex-grow: 1;
              min-width: 12rem;
              scroll-snap-align: start;
              opacity: ${(props) => (props.theme.theme === "dark" ? 1 : 1)};

              &:nth-child(5n + 1) {
                margin-top: -1.5rem;
              }
              &:nth-child(5n + 3) {
                margin-top: -2rem;
              }
              &:nth-child(5n + 4) {
                margin-top: 2rem;
              }
              &:nth-child(5n + 5) {
                margin-top: 0.5rem;
              }
            }

            picture {
              position: relative;
              display: block;

              &:after {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: #0d0d0d;
                opacity: ${(props) =>
                  props.theme.theme === "light" ? 0 : 0.3};
              }
            }
          `}
        >
          {!isFetching &&
            posts.length > 0 &&
            posts.map((post) => (
              <div key={post.id}>
                <picture>
                  <img src={post.image} alt="" />
                </picture>
              </div>
            ))}
        </div>
      </Section>
      <div
        css={`
          background: ${(props) => props.theme.colors.background};
          height: 6rem;
          width: 100%;
        `}
      ></div>
      <NewsletterSignup />
    </>
  )
}

export const query = graphql`
  query FrontPage {
    file(relativePath: { eq: "beeldmerk-black.png" }) {
      childImageSharp {
        fixed(width: 176, height: 433, webpQuality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    wp {
      page(id: 6, idType: DATABASE_ID) {
        title
        uri
        blocks {
          ...Block
        }
        seo {
          ...SEO
        }
        acfHomeBanners {
          bannerDay {
            link
            linkLabel
            subtitle
            image {
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(maxWidth: 960, maxHeight: 1280, webpQuality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          bannerEvening {
            link
            linkLabel
            subtitle
            image {
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(maxWidth: 960, maxHeight: 1280, webpQuality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          imageIntro {
            sourceUrl
            localFile {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 800, webpQuality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      persons(first: 4) {
        edges {
          node {
            persoon {
              role
            }
            id
            content
            title
            slug
            featuredImage {
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(maxWidth: 480, maxHeight: 680, webpQuality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default FrontPage

type BannerProps = {
  content:
    | Wp_Page_Acfhomebanners_BannerDay
    | Wp_Page_Acfhomebanners_BannerEvening
    | undefined
  title: string
}

const Banner: React.FC<BannerProps> = ({ content, title }) => {
  if (!content?.image) return null
  return (
    <div
      css={`
        position: relative;
        width: 100%;
        height: 100%;
        opacity: 1 !important;

        & > div:first-child {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: ${(props) => props.theme.headerImageOpacity};
        }

        & > div:last-child {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: absolute;
          color: ${(props) => props.theme.colors.white};
          left: 0;
          top: 0;

          h2 {
            font-size: calc(3rem + 2.5vw);
            text-transform: uppercase;
            line-height: 1;
            font-family: ${(props) => props.theme.fonts.heading};
            margin-bottom: -0.5rem;
            letter-spacing: 0.25rem;
          }

          p {
            margin-bottom: 2rem;
            line-height: 1;
          }

          a {
            background-color: ${(props) => props.theme.colors.white};
          }
        }
      `}
    >
      <BackgroundImage
        preserveStackingContext={true}
        fluid={content.image.localFile?.childImageSharp?.fluid as IFluidObject}
      ></BackgroundImage>
      <div>
        <h2>{title}</h2>
        <p>{content.subtitle}</p>
        <ButtonLink href={content.link} level={ButtonLevel.tertiary}>
          {content.linkLabel}
        </ButtonLink>
      </div>
    </div>
  )
}
